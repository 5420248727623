import React from "react"
// import { Link } from "gatsby"

import pam from "../images/pam-banner.jpg"
// IMAGEN DEL banner

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
// import '../components/font-awesome';
// import { Accordion, Card } from "react-bootstrap"
// import ReactPlayer from "react-player"

import Layout from "../components/layout"
import SEO from "../components/seo"
import News from "../components/news"
// import CourseForm from "../components/course-form"

const webcentrix={
    Level:'', 
    academicUnit: 'ICDA', 
    career: 'alumni'
}


const alumniPage = ({ data }) => (
    <Layout isAlumni={true} >
        <SEO title="Alumni - ICDA" />
        <section className="mb-4">
            {/* Banner y Titulo */}
            <div className="w-100 d-flex bg-light inst-img mb-4" style={{ backgroundImage: `url(${pam})` }}>
                <div className="w-100 flex-grow-1 d-flex flex-column align-items-center justify-content-center text-center text-white p-5 background">
                    <h2 className="mb-4 fw-bold text-uppercase title">
                        Alumni ICDA
                    </h2>
                </div>
            </div>
            <div className="container">
                <div className="my-5">
                    <h3 className="font-weight-bold">
                        Alumni UCC | ICDA
                    </h3>
                    <p>
                        Es una comunidad de profesionales global e intergeneracional, entre quienes compartieron la educación de excelencia que brinda ICDA - Escuela de Negocios de la Universidad Católica de Córdoba.
                    </p>
                    <p>
                        Un espacio de reencuentro para promover el networking y el surgimiento de iniciativas conjuntas que potencien los proyectos profesionales, como así también el encuentro personal en un marco de confianza, entre personas formadas en un mismo espíritu humanista y que comparten el afecto por su Universidad. 
                    </p>
                </div>

                {/* Map Alumni */}
                <div>
                    <h3 className="font-weight-bold">
                        Conectando Profesionales | Alumni UCC | ICDA 
                    </h3>
                    <p>
                        Encontrá un espacio donde potenciar tu desarrollo profesional, intercambiar ideas y proyectos, y generar vínculos con tus colegas, estrechando lazos, compartiendo experiencias, transmitiendo conocimiento y potenciando el networking.
                    </p>
                    <iframe title="mapa" src="https://www.google.com/maps/d/embed?mid=11F0hsJItujUGxHXcGQJfo0_TpyzgCfA&ehbc=2E312F" width="100%" height="500" loading="lazy" className="border-0 mb-3"></iframe>
                    <p>
                        Si queres entrar en contacto con colegas <span className="font-weight-bold">Alumni UCC | ICDA</span> contactanos: Área de Alumni UCC: <span className="text-primary">alumni@ucc.edu.ar</span> / +54 9 351 7556271.
                    </p>
                    <p>
                        Si deseas sumar tu experiencia y perfil al mapa, actualiza tus datos ingresando <a href="https://docs.google.com/forms/d/e/1FAIpQLSfxrZW09L5ErdLnbRq6cWUoeU9Zs0CW1dtheIAGfTevTNtYZA/viewform" target="_blank" className="text-primary font-weight-bold">AQUÍ</a>
                    </p>
                </div>

                {/* News */}
                {data.allStrapiNovelties &&
                data.allStrapiNovelties.edges.length > 0 ? (
                    <div className="my-5">
                        <News news={data.allStrapiNovelties.edges} />
                    </div>
                ) : null}

                {/* Dropdown de Texto */}
                {/* <div className="my-5">
                    <h4 className="font-weight-bold">
                        MODALIDADES
                    </h4>
                    <p>
                        Dependiendo de los requermientos particulares de desarrollo de talento de cada organización, los prorgramas de capacitación a medida pueden llevarse a cabo a través de alguna de las siguientes modalidades:
                    </p>
                    <div className="mb-3 bg-light px-4 py-2">
                        <div className="my-2 py-2 border-bottom">
                            <h6 className="text-sec mb-2">
                                UNIVERSIDAD CORPORATIVA
                            </h6>
                            <p>
                                Se concibe como un proceso de educación continua a través del cual las empresas gestionan el conocimiento, integran, promueven y garantizan una formación estratégica para sus recursos humanos. Cada año esta capacitación se va adaptando a la realidad vigente de la organización que la realiza.
                            </p>
                        </div>
                        <div className="my-2 py-2 border-bottom">
                            <h6 className="text-sec mb-2">
                                PROGRAMAS EJECUTIVOS
                            </h6>
                            <p>
                                Propuestas codiseñadas entre el ICDA y la organización, que permiten en poco tiempo desarrollar habilidades y competencias específicas en alguna de las áreas funcionales de la gestión organizacional: Marketing, Finanzas, Recursos Humanos, Logística, Responsabilidad Social, Liderazgo, entre otras
                            </p>
                        </div>
                        <div className="my-2 py-2 border-bottom">
                            <h6 className="text-sec mb-2">
                                CURSOS DE POSGRADO
                            </h6>
                            <p>
                                Son programas de mediana duración para colaboradores que cuenten con formación de grado previa. La certificación del ICDA es acompañada por un legajo UCC.
                            </p>
                        </div>
                        <div className="my-2 py-2 border-bottom">
                            <h6 className="text-sec mb-2">
                                ENTRENAMIENTOS INTENSIVOS
                            </h6>
                            <p>
                                Talleres de corta duración concebidos en un ambiente participativo y de comunicación abierta, con dinámicas prácticas para alcanzar los objetivos de forma inmediata, trabajar y resolver situaciones específicas.
                            </p>
                        </div>
                        <div className="my-2 py-2 border-bottom">
                            <h6 className="text-sec mb-2">
                                OUTDOORS EXPERIENCIALES
                            </h6>
                            <p>
                                Jornadas de trabajo al aire libre, con actividades lúdicas y de simulación coordinadas por facilitadores especializados en comunicación, trabajo en equipo y liderazgo.
                            </p>
                        </div>
                        <div className="my-2 py-2">
                            <h6 className="text-sec mb-2">
                                JORNADAS ESPECIALIZADAS
                            </h6>
                            <p>
                                Seminarios, conferencias o desayunos de trabajo diseñados para un área o grupo de personas determinado de la organización. Estas actividades están a cargo de reconocidos docentes y consultores con expertise en diferentes temáticas de la realidad actual.
                            </p>
                        </div>
                    </div>
                </div> */}

                {/* video */}
                {/* <div className="container row w-70 h-10 mb-5 d-flex align-items-center justify-content-between">
                    <div className="col-md-6 col-12">
                        <h4 className="font-weight-bold">
                            The War Room - ICDA - MBA Gonzalo García Espeche
                        </h4>
                    </div>
                    <div className="col-md-6 col-12 embed-responsive embed-responsive-16by9 container">
                        <ReactPlayer
                            url={"https://www.youtube.com/watch?v=g89KICYIgAw&t=6s"}
                            playing={false}
                            muted={true}
                            loop={true}
                            className="fembed-responsive-item"
                            width="100%"
                            height="100%"
                        />
                    </div>
                </div> */}

                {/* video */}
                {/* <div className="container row w-70 h-10 mb-5 d-flex align-items-center justify-content-between">
                    <div className="col-md-6 col-12">
                        <h4 className="font-weight-bold">
                            Liderar equipos de digital workers - ICDA - Mgter. Carlos Spontón
                        </h4>
                    </div>
                    
                    <div className="col-md-6 col-12 embed-responsive embed-responsive-16by9 container">
                        <ReactPlayer
                            url={"https://www.youtube.com/watch?v=hswMmXHAiWk"}
                            playing={false}
                            muted={true}
                            loop={true}
                            className="fembed-responsive-item"
                            width="100%"
                            height="100%"
                        />
                    </div>
                </div> */}

                {/* video */}
                {/* <div className="container row w-70 h-10 mb-5 d-flex align-items-center justify-content-between">
                    <div className="col-md-8 col-12">
                        <h4 className="font-weight-bold">
                            Liderar equipos de digital workers - ICDA - Mgter. Carlos Spontón
                        </h4>
                    </div>
                    <div className="col-md-4 col-12">
                        <a target="_blank" href="https://www.youtube.com/watch?v=hswMmXHAiWk" className="button-vid button-video">
                            <span>Ver video</span>
                        </a>
                    </div> 
                </div> */}

                {/* <div className="my-5">
                    <CourseForm webcentrix={webcentrix}/>
                </div> */}
            </div>
        </section>
    </Layout>
)


export default alumniPage

export const internationalQuery = graphql`
  query {
    allStrapiNovelties(
      limit: 4
      filter: { 
        academic_unit: { name: { eq: "ICDA" } }
        novelties_categories: { elemMatch: { name: { eq: "Alumni" } } } 
      }
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          id
          date(formatString: "DD MMMM, YYYY", locale: "es")
          summary
          title
          content
          imageResume {
            image {
              id
              url
            }
          }
          published_at(formatString: "DD MMMM, YYYY", locale: "es")
          novelties_categories {
            name
          }
        }
      }
    }
  }
`
